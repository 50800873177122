
/*------------------------------------
  # SPEAKERS PAGE
\*------------------------------------*/
.speaker-wrap {
  padding-bottom: 100px;

  @include respond-to(xs) {
    padding-bottom: 40px;
  }
}

.persons {
  margin-top: 50px;
}


.personc {
  margin-bottom: 70px;

  @include respond-to(extra_xs) {
    margin-bottom: 30px;
  }
}

.person-section {
  border-bottom: 1px solid $white;
  padding-bottom: 30px;
  margin-bottom: 45px;
}

.person {
  .thumb img {
    cursor: default;
    display: block;
    height: auto;
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    transition: all 0.2s;
  }



  h3 {
    color: $main_blue;
    @include font-size(1.8);
    padding-top: 15px;
    padding-bottom: 5px;
  }

  h4 {
    font-weight: normal;
    color: $main_blue;
    line-height: 22px;
    @include font-size(1.6);
    padding-bottom: 30px;
  }

  @include respond-to(xs) {
    margin: 0 auto;
    max-width: 260px;

    h3 {
      padding-top: 5px;
      padding-bottom: 0;
      @include font-size(1.6);
    }

    h4 {
      @include font-size(1.4);
      padding-bottom: 15px;
      line-height: 19px;
    }
  }
}

.person-content {
  .person-link {
    @include font-size(1.6);
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.4s all;

    &:hover {
      text-decoration: none;
    }
  }
}

.person-big {
  background-color: $white;

  .thumb {
    padding-top: 51px;
    padding-bottom: 25px;
    padding-left: 35px;
    padding-right: 35px;

    img {
      max-width: 303px;
      max-height: 303px;
      width: 100%;
      margin: auto;
    }
  }

  .person-content {
    padding-bottom: 40px;
    font-family: $avenir;
    color: $black;

    .person-header {
      padding-left: 35px;
      padding-right: 35px;
      color: $black;

      h3 {
        text-transform: initial;
        font-family: $avenir;
        padding-bottom: 10px;
      }

      h4 {
        font-weight: normal;
        padding-bottom: 15px;


        span {
          display: inline-block;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .person-description {
      margin-top: 0;
      padding: 0 35px 55px;
      border-top: none;
      color: $black;
      border-bottom: 1px solid $black;

      p {
        @include font-size(1.6);
        line-height: 26px;
        margin-bottom: 20px;
      }
    }

    .person-sessions {
      margin-top: 0;
      padding: 30px 35px 55px;
      color: $black;
      line-height: 26px;
      border-bottom: 1px solid $black;

      a {
        text-decoration: underline;
        color: $main_blue;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .btn {
      margin-top: 40px;
    }
  }

  .popup-link {
    display: block;
    text-align: center;
    color: $main_blue;
  }
}

.person-fancybox {
  max-width: 600px;
  padding-bottom: 20px;
  width: 90% !important;

  .fancybox-inner {
    width: 100% !important;
  }
}

/*** Speakers page - Person Style ***/
.person-big,
.co-chairs-space {
  .person-header {
    color: $main_blue;

    h3 {
      @include font-size(2.5);
      text-transform: uppercase;
      padding: 5px 0 3px;
    }

    h4 {
      @include font-size(1.6);
      padding-bottom: 10px;
    }
  }

  .person-description {
    margin-top: 20px;
    padding-top: 20px;

    border-top: 1px solid $main_blue;
    color: $main_blue;

    p {
      @include font-size(1.8);
      line-height: 24px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.person-sessions {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid $main_blue;
  color: $main_blue;

  .sessions-entry {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    text-transform: uppercase;
    @include font-size(2);
    font-family: $avenir;
    margin-bottom: 19px;
  }

  a {
    display: block;
    color: $main_blue;
    margin-bottom: 15px;
  }

  p {
    @include font-size(1.6);
  }

  @include respond-to(sm) {
    .sessions-entry {
      margin-bottom: 20px;
    }
  }
}

/*------------------------------------
  # SPEAKERS DETAIL PAGE
\*------------------------------------*/
.person-detail {
  color: $main_blue;
  font-family: $avenir;

  h3 {
    @include font-size(2.5);
    // font-family: 'Eczar', sans-serif;
  }

  h4 {
    font-weight: normal;
    @include font-size(1.6);
    margin-bottom: 35px;

    span {
      display: inline-block;
    }

    p {
      margin-bottom: 0;
    }
  }

  p {
    line-height: 24px;
    @include font-size(1.6);
    margin-bottom: 25px;
  }

  .social-buttons a {
    color: $main_blue;
    border: 1px solid $main_blue;
    background-color: transparent;

    .fa {
      margin-top: -11px;
      font-size: 22px;
    }

    &:hover {
      .fa {
        color: $main_blue;
      }
      background-color: $white;
    }
  }

  @include respond-to(sm) {
    h3 {
      margin-bottom: 10px;
    }

    h4 {
      font-family: $avenir;
    }

    img {
      margin-bottom: 20px;
    }
  }
}

.detail-session {
  border: none;
  color: $white;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @include respond-to(sm) {
    padding-bottom: 35px;
  }
}