
/*------------------------------------
  # THANKS PAGE
\*------------------------------------*/
.thanks-block {
  text-align: center;

  .title {
    margin-bottom: 20px;
    @include font-size(3.5);
  }

  h5 {
    @include font-size(2.3);
    margin:0 0 30px 0;
    text-transform:uppercase;
  }

  .text {
    margin: 50px 0 40px;

    p:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .btn-group {
    margin-top:30px;
    display: flex;
    max-width: 813px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;

    .btn {
      margin: 0;
      color: $white;
      width: 100%;
      margin-bottom: 40px;
    }
  }

  @include respond-to(xs) {
    padding: 50px 10px 10px;

    .btn-group {
      flex-direction: column;

      .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}