// Transition
$transitionAll: 0.4s all;

// Pallete
$black: #000;
$white: #fff;

$main_blue: #0b2133;
$yellow: #ffdd15;
$light_grey: #e6e6e6;
$dark_grey: #2b3544;