
/** A/M Dynamic form styles **/
.registration-form {
  .short-text {
    color: $white;
    @include font-size(1.8);
    margin-bottom: 37px;

    @include respond-to(xs) {
      text-align: center;
      margin-left: 0;
      margin-bottom: 25px;
    }
  }

  .form {
    fieldset {
      border: none;
    }

    .form__note {
      display: none;
    }

    &.intake-form {
      .form__row {
        input,
        textarea,
        select {
          height: 55px;

          @include respond-to(xs) {
            height: 50px;
          }
        }
      }
    }

    .form_dsc {
      p {
        color:$white;
        margin:12px 0 0 0;
        font-size:1.6rem;
      }
    }

    .form__row {
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;

      @include respond-to(xs) {
        margin-bottom: 20px;
      }

      .form__label {
        margin-bottom: 5px;

        label {
          color: $white;
          @include font-size(1.8);
        }
      }

      .form__field {
        position: relative;
      }

      input,
      textarea,
      select {
        border: none;
        background-color: $white;
        outline: none;
        width: 100%;
        height: 65px;
        resize: none;
        padding-left: 15px;
        border-radius: 5px;
        @include font-size(1.8);

        @include respond-to(xs) {
          height: 50px;
        }
      }

      select {
        cursor: pointer;
        background:transparent;
        appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
      }

      textarea {
        min-height: 290px;
        padding-top: 15px;
      }
    }

    .select {
      position: relative;
      background-color: $white;
      height: 65px;
      border-radius: 5px;

      &:before {
        top: 50%;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #662d91;
        content: '';
        position: absolute;
        right: 23px;
        transform: translateY(-50%);
      }

      select::-ms-expand {
        display: none;
      }

      @include respond-to(xs) {
        height: 50px;
      }
    }

    button {
      display: block;
      width: 100%;
      border: none;
      margin-left: auto;
      margin-right: auto;

      height: 42px;
      max-width: 232px;

      text-transform: uppercase;
      text-align: center;
      transition: $transitionAll;

      background-color: $white;

      &:hover,
      &:focus,
      &:active {
        color: $white;
      }
    }
  }
}

/** Parsley styles **/
.parsley-errors-list {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;

  color: $white;
  @include font-size(1.1);

  li {
    padding: 4px;
    border: 1px solid $white;
  }
}