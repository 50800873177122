footer {
  background: $main_blue;
  position: relative;
  padding-top: 80px;
  padding-bottom: 50px;

  @include respond-to(xs) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.footer {
  padding-top: 85px;

  @include respond-to(extra_xs) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  svg {
    max-width: 100%;
  }

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-inner-wrap {
  display: flex;

  @include respond-to(xs) {
      flex-wrap: wrap;
  }

  .footer-inner-item {
    width: 33%;
    border-right: 1px solid $white;
    padding: 20px 35px;
    color: $white;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    @include respond-to(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include respond-to(xs) {
      width: 100%;
      margin-bottom: 30px;
      border: none;

      & > * {
        margin-bottom: 25px;
      }
    }

    &:last-child {
      border: none;
    }

    h3 {
      text-transform: uppercase;
      font-family: $avenir;
      @include font-size(1.8);
      font-weight: 500;
      letter-spacing: 3.6px;
      width: 100%;

      @include respond-to(sm) {
        @include font-size(1.6);
      }
    }

    p {
      @include font-size(1.8);
      font-weight: 400;
      line-height: 1.4;
      width: 100%;
    }

    a {
      color: inherit;
      @include font-size(1.8);

      &:hover {
        color: $yellow;
      }
    }

    .accommodation-item {
      margin-top: 12px;
      margin-bottom: 7px;
    }

    .contactUs {
      display: inline-block;
      line-height: 40px;
      padding: 0 15px;
      text-transform: uppercase;
      color: $main_blue;
      font-family: $avenir;
      @include font-size(1.8);
      font-weight: 500;
      letter-spacing: 3.6px;
      border-radius: 20px;
      border: 1px solid $light_grey;
      text-align: center;
      background: $light_grey;

      &:hover {
        background: none;
        color: $light_grey;
      }
    }

    .social-block {
      display: flex;

      @include respond-to(xs) {
        width: 100%;
        justify-content: center;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51px;
        height: 51px;
        background: $light_grey;
        border-radius: 50%;
        margin: 0 17px;
        border: 1px solid $light_grey;

        svg {
          fill: $main_blue;
        }

        &:hover {
          background: none;

          svg {
            fill: $light_grey;
          }
        }
      }
    }
  }
}