@font-face {
    font-family: 'Avenir Next Ultra';
    src: url('../fonts/AvenirNext-UltraLight.eot');
    src: url('../fonts/AvenirNext-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-UltraLight.woff2') format('woff2'),
        url('../fonts/AvenirNext-UltraLight.woff') format('woff'),
        url('../fonts/AvenirNext-UltraLight.ttf') format('truetype'),
        url('../fonts/AvenirNext-UltraLight.svg#AvenirNext-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Italic.eot');
    src: url('../fonts/AvenirNext-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Italic.woff2') format('woff2'),
        url('../fonts/AvenirNext-Italic.woff') format('woff'),
        url('../fonts/AvenirNext-Italic.ttf') format('truetype'),
        url('../fonts/AvenirNext-Italic.svg#AvenirNext-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Heavy.eot');
    src: url('../fonts/AvenirNext-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Heavy.woff2') format('woff2'),
        url('../fonts/AvenirNext-Heavy.woff') format('woff'),
        url('../fonts/AvenirNext-Heavy.ttf') format('truetype'),
        url('../fonts/AvenirNext-Heavy.svg#AvenirNext-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/AvenirNext-DemiBoldItalic.eot');
    src: url('../fonts/AvenirNext-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
        url('../fonts/AvenirNext-DemiBoldItalic.woff') format('woff'),
        url('../fonts/AvenirNext-DemiBoldItalic.ttf') format('truetype'),
        url('../fonts/AvenirNext-DemiBoldItalic.svg#AvenirNext-DemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/AvenirNext-DemiBold.eot');
    src: url('../fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-DemiBold.woff2') format('woff2'),
        url('../fonts/AvenirNext-DemiBold.woff') format('woff'),
        url('../fonts/AvenirNext-DemiBold.ttf') format('truetype'),
        url('../fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Bold.eot');
    src: url('../fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNext-Bold.woff') format('woff'),
        url('../fonts/AvenirNext-Bold.ttf') format('truetype'),
        url('../fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Medium.eot');
    src: url('../fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff'),
        url('../fonts/AvenirNext-Medium.ttf') format('truetype'),
        url('../fonts/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Regular.eot');
    src: url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNext-Regular.woff') format('woff'),
        url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
        url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}