// RESOURCES

.resources-wrap {
  background: $main_blue;
  padding-top: 85px;
  padding-bottom: 165px;
  position: relative;

  @include respond-to(extra_xs) {
    padding-top: 40px;
    padding-bottom: 65px;
  }

  h1 {
    color: $white;
    @include font-size(6);
    font-family: $avenir;
    font-weight: 500;
    margin: 0;
  }
}

.second-block {
  padding-top: 65px;

  p {
    color: $main_blue;
    font-family: $avenir;
    @include font-size(1.8);
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 35px;
  }
}

.resources-inner-block {
  h2 {
    margin-bottom: 75px;

    @include respond-to(xs) {
      margin-bottom: 50px;
    }
  }

  .button-block {
    display: flex;
    justify-content: space-between;
    padding: 85px 0 80px;

    @include respond-to(sm) {
      flex-wrap: wrap;
      padding: 40px 0;
    }

    a {
      display: inline-block;
      line-height: 45px;
      background: $light_grey;
      padding: 0 15px;
      text-decoration: none !important;
      text-transform: uppercase;
      color: $main_blue;
      font-family: $avenir;
      @include font-size(1.4);
      font-weight: 500;
      letter-spacing: 2px;
      border-radius: 22px;
      text-align: center;

      &:hover,
      &:active,
      &:focus {
        color: $white;
        background: $main_blue;
      }

      @include respond-to(sm) {
        margin-bottom: 15px;
        width: 49%;
      }

      @include respond-to(xs) {
        width: 100%;
      }
    }
  }

  .resources-inner-wrapper {
    margin-bottom: 60px;

    .resources-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .item {
      position: relative;
      text-align: left;
      padding-bottom: 20px;
      border-bottom: 1px solid $light_grey;
      flex-basis: 47%;
      margin-bottom: 25px;

      @include respond-to(xs) {
        flex-basis: 100%;
      }

      .title {
        text-transform: uppercase;
        color: $main_blue;
        font-family: $avenir_demi;
        @include font-size(1.4);
        font-weight: bold;
        letter-spacing: 2.8px;
        text-align: left;
        margin-bottom: 5px;
      }

      .author {
        color: #b3b3b3;
        font-family: $avenir;
        @include font-size(1.8);
        font-weight: 400;
        margin-bottom: 0;
      }

      .view {
        position: absolute;
        right: 0;
        top: 3px;
        display: inline-block;
        background: $main_blue;
        padding: 0 10px;
        text-transform: uppercase;
        color: $white;
        font-family: $avenir;
        @include font-size(1.4);
        font-weight: 500;
        letter-spacing: 2.8px;
        border: 1px solid $main_blue;
        line-height: 43px;
        border-radius: 22px;

        &:hover {
          color: $main_blue;
          background: none;
        }
      }
    }
  }
}

.blue-footer-block {
  position: relative;
  background: $main_blue;
  padding: 75px 0;
  margin-top: 45px;
}