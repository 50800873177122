.banner-wrapper {
  background: $main_blue;
  padding-top: 60px;
  padding-bottom: 130px;

  .date-place {
    color: $white;
    font-family: $avenir;
    @include font-size(2.4);
    font-weight: 500;
    margin-bottom: 50px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 40px;

    @include respond-to(extra_xs) {
      svg {
        max-width: 100%;
      }
    }
  }


  h2 {
    text-transform: uppercase;
    color: $white;
    font-family: $avenir;
    @include font-size(3.6);
    font-weight: bold;
    letter-spacing: 7.2px;

    @include respond-to(sm) {
      @include font-size(3);
    }
  }

  .after-title {
    text-transform: uppercase;
    color: $yellow;
    font-family: $avenir;
    @include font-size(1.8);
    font-weight: 500;
    letter-spacing: 3.6px;
    margin-bottom: 65px;
  }

  .learn-more {
    text-transform: uppercase;
    color: $main_blue;
    font-family: $avenir;
    @include font-size(2.4);
    font-weight: 500;
    letter-spacing: 4.8px;
    line-height: 54px;
    padding: 0 25px;
    border: 1px solid $light_grey;
    background: $light_grey;
    border-radius: 28px;
    display: inline-block;
    position: relative;
    z-index: 50;

    &:hover {
      background: none;
      color: $light_grey;
    }

    @include respond-to(sm) {
      @include font-size(2);
      line-height: 50px;
      border-radius: 25px;
    }

    @include respond-to(xs) {
      @include font-size(1.6);
      padding: 0 20px;
    }
  }
}

.section {
  padding-top: 185px;
  padding-bottom: 150px;
  position: relative;

  @include respond-to(sm) {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  h2 {
    margin-bottom: 25px;
  }

  p {
    color: $main_blue;
    font-family: $avenir;
    @include font-size(1.8);
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 40px;
  }

  span {
    font-family: $avenir_demi;
    font-weight: 600;
  }
}

.triangle-block {
  position: absolute;
  display: block;
  height: 210px;
  width: 50%;
  z-index: 10;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-size: cover !important;
  }
}

.white-top-left-block {
  bottom: 0;
  left: 0;

  &:before {
    bottom: 0;
    right: 0;
    background: url(../img/white-top.svg) no-repeat right bottom;
  }
}

.white-top-right-block {
  bottom: 0;
  right: 0;
  transform:rotateY(180deg);

  &:before {
    bottom: 0;
    right: 0;
    background: url(../img/white-top.svg) no-repeat right bottom;
  }
}

.white-bottom-left-block {
  top: 0;
  left: 0;
  transform: rotateX(180deg);

  &:before {
    bottom: 0;
    right: 0;
    background: url(../img/white-top.png) no-repeat right bottom;
  }
}

.white-bottom-right-block {
  top: 0;
  right: 0;
  transform:rotate(180deg);

  &:before {
    bottom: 0;
    right: 0;
    background: url(../img/white-top.png) no-repeat right bottom;
  }
}

.grey-top-triangle {
  bottom: 100%;

  &:before {
    top: 2px;
    right: 0;
    background: url(../img/grey-top.png) no-repeat right bottom;
  }
}

.grey-bottom-triangle {
  top: 100%;
  transform:rotateY(180deg);
  right: 0;

  &:before {
    top: -2px;
    right: 0;
    background: url(../img/grey-bottom.png) no-repeat right bottom;
  }
}

.yellow-top-triangle {
  bottom: 100%;
  right: 0;

  &:before {
    top: 2px;
    right: 0;
    background: url(../img/yellow-top.png) no-repeat right bottom;
    transform:rotateY(180deg);
  }

  &:after {
    transform: rotateY(180deg);
    top: 100%;
    right: 0;
    background: url(../img/yellow-bottom.png) no-repeat right bottom;
  }
}

.yellow-bottom-triangle {
  top: 100%;

  &:before {
    right: 0;
    bottom: 100%;
    background: url(../img/yellow-top.png) no-repeat right bottom;
  }

  &:after {
    right: 0;
    top: -2px;
    background: url(../img/yellow-bottom.png) no-repeat right bottom;
  }
}


.section-2 {
  background: $light_grey;
}

.section-3 {
  .view-programs {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    font-family: $avenir;
    @include font-size(2.4);
    font-weight: 500;
    letter-spacing: 4.8px;
    padding: 0 35px;
    line-height: 54px;
    border: 1px solid $dark_grey;
    background: $dark_grey;
    border-radius: 27px;
    margin-top: 20px;

    &:hover {
      background: none;
      color: $dark_grey;
    }

    @include respond-to(sm) {
      @include font-size(2);
      line-height: 50px;
      border-radius: 25px;
    }

    @include respond-to(xs) {
      @include font-size(1.6);
      padding: 0 20px;
    }
  }
}

.section-4 {
  background: $main_blue;
  color: $white;

  p {
    color: $white;
  }

  .section-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }

    .section-inner-item {
      @include respond-to(xs) {
        width: 50%;
        margin-bottom: 30px;
      }
    }

    p {
      width: 206px;
      height: 206px;
      border-radius: 50%;
      border: 1px solid $yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font-size(3.6);
      color: $light_grey;
      flex-direction: column;
      line-height: 1;
      font-family: $avenir_demi;
      font-weight: 600;
      flex-shrink: 0;
      margin-bottom: 0;

      span {display: block;}

      @include respond-to(sm) {
        width: 150px;
        height: 150px;
        @include font-size(2.5);
      }

      @include respond-to(xs) {
        margin-left: auto;
        margin-right: auto;
      }

      @include respond-to(extra_xs) {
        width: 130px;
        height: 130px;

        @include font-size(2.2);
      }
    }
  }
}

.section-5 {
  .meet-inner-block {
    width: 700px;
    max-width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 55px auto 0;

    @include respond-to(extra_xs) {
      flex-wrap: wrap;
    }

    .wrapImg {
      margin-bottom: 0;
      width: 350px;
      height: 350px;
      overflow: hidden;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 62px;

      @include respond-to(sm) {
        width: 250px;
        height: 250px;
        margin-right: 30px;
      }

      @include respond-to(extra_xs) {
        margin-bottom: 30px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .name {
      color: $main_blue;
      font-family: $avenir;
      @include font-size(3.6);
      font-weight: bold;
      margin-bottom: 0;

      @include respond-to(sm) {
        @include font-size(3);
      }
    }

    .position {
      color: $main_blue;
      font-family: $avenir;
      @include font-size(2.4);
      font-weight: 500;
      margin-bottom: 23px;

      span {
        font-family: inherit;
        font-weight: inherit;
      }

      @include respond-to(sm) {
        @include font-size(2);
      }
    }

    .bio-btn {
      display: inline-block;
      text-transform: uppercase;
      color: $white;
      font-family: $avenir;
      @include font-size(2.4);
      font-weight: 500;
      letter-spacing: 4.8px;
      padding: 0 15px;
      background: $dark_grey;
      line-height: 54px;
      border: 1px solid $dark_grey;
      border-radius: 28px;
      position: relative;
      z-index: 50;
      cursor: pointer;

      &:hover {
        background: none;
        color: $dark_grey;
      }

      @include respond-to(sm) {
        @include font-size(2);
        line-height: 50px;
        border-radius: 25px;
      }

      @include respond-to(xs) {
        @include font-size(1.6);
        padding: 0 20px;
      }
    }
  }
}

.section-6 {
  background: $light_grey;

  .subtitle {
    font-family: $avenir_demi;
    @include font-size(1.8);
    font-weight: bold;
    margin-bottom: 0;

    &.subtitle-last {
      margin-bottom: 40px;
    }
  }

  a {
    @include font-size(1.8);
    font-family: $avenir_demi;
    font-weight: bold;
  }

  .contactUs-btn {
    display: inline-block;
    text-transform: uppercase;
    background: $dark_grey;
    color: $white;
    font-family: $avenir;
    @include font-size(2.4);
    font-weight: 500;
    letter-spacing: 4.8px;
    padding: 0 35px;
    line-height: 54px;
    border: 1px solid $dark_grey;
    border-radius: 28px;
    margin-top: 15px;
    position: relative;
    z-index: 50;

    &:hover {
      background: none;
      color: $dark_grey;
    }

    @include respond-to(sm) {
      @include font-size(2);
      line-height: 50px;
      border-radius: 25px;
    }
  }
}

.section-7 {
  background: $main_blue;
  color: $white;
  padding-bottom: 45px;

  p {
    color: $white;
    margin-bottom: 5px;

    &.date {
      font-family: $avenir_demi;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}