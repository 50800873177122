/* Header
================================================== */
.before-header {
  background: $main_blue;
  padding-bottom: 35px;

  @include respond-to(xs) {
    display: none;
  }
}

header {

  &.fedlab-header {
    padding: 0;

    @include respond-to(xs) {
      padding: 10px 0;
      background: $main_blue;

      .mobile-wrapper {
        display: -webkit-flex !important;
        display: -moz-flex !important;
        display: -ms-flex !important;
        display: -o-flex !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .logo-mob {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  @include respond-to(xs) {
    position: fixed;
    top: 0;
    width: 100%;
    // height: 56px;
    z-index: 101;
    padding: 12px 0;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .mobile-logotext,
  .logotext {
    font-family: $avenir;
    font-weight: 500;
    @include font-size(1.8);
    color: $main_blue;
    align-items: center;
    transition: $transitionAll;
  }

  .mobile-logotext {
    margin-left: 10px;
  }
}

.block-title {
  display: block;
  white-space: nowrap;
}

.registration-bar {
  text-align: right;

  .nav-top li {
    a {
      display: inline-block;
      background: $light_grey;
      text-transform: uppercase;
      color: $main_blue;
      font-family: $avenir;
      @include font-size(1.8);
      font-weight: 500;
      letter-spacing: 3.6px;
      padding: 0 15px;
      line-height: 40px;
      border-radius: 21px;
      border: 1px solid $light-grey;

      &:hover {
        background: none !important;
        color: $light-grey;
      }

      &:after {
        display: none !important;
      }

      @include respond-to(sm) {
        @include font-size(1.4);
        line-height: 35px;
        letter-spacing: 3px
      }
    }
  }
}


.main-menu {
  display: flex;
  align-items: center;
}