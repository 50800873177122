// Font size
@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + rem;
  font-size: ($sizeValue * 10) + px;
}

// Placeholders
@mixin placeholder {
  input::-webkit-input-placeholder {@content}
  input:-moz-placeholder           {@content}
  input::-moz-placeholder          {@content}
  input:-ms-input-placeholder      {@content}
  textarea::-webkit-input-placeholder {@content}
  textarea:-moz-placeholder           {@content}
  textarea::-moz-placeholder          {@content}
  textarea:-ms-input-placeholder      {@content}
}

@mixin placeholderFocus {
  input:focus::-webkit-input-placeholder {@content}
  input:focus:-moz-placeholder           {@content}
  input:focus::-moz-placeholder          {@content}
  input:focus:-ms-input-placeholder      {@content}

  textarea:focus::-webkit-input-placeholder {@content}
  textarea:focus:-moz-placeholder           {@content}
  textarea:focus::-moz-placeholder          {@content}
  textarea:focus:-ms-input-placeholder      {@content}
}

// respond-to()
// ------------
// Extra small screen / phone
$screen-xs: 480px;

// Small screen / tablet
$screen-sm: 768px;

// Medium screen / desktop
$screen-md: 992px;

// Large screen / wide desktop
$screen-lg: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

@mixin respond-to($media) {
  @if $media == xs {
    @media (max-width: $screen-xs-max) { @content }
  }
  @else if $media == extra_xs {
    @media (max-width: $screen-xs) { @content }
  }
  @else if $media == sm {
    @media (max-width: $screen-sm-max) { @content }
  }
  @else if $media == md {
    @media (max-width: $screen-md-max) { @content }
  }
  @else if $media == lg {
    @media (min-width: $screen-lg) { @content }
  }
}