/*------------------------------------
  # PROGRAM PAGE
\*------------------------------------*/

.program-block {
  margin: 50px auto;

  margin-left: auto;
  margin-right: auto;

  @include respond-to(xs) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.program-top {
  text-align: center;
  padding-top: 30px;
  margin-bottom: -20px;
  color: $white;

  .detail-social {
    text-align: left;
    margin-top: 10px;
  }

  h2 {
    margin-bottom: 15px;
  }

  h3 {
    @include font-size(2.5);
    margin-bottom: 5px;
    font-family: $avenir;
  }

  .personaldrop {
    font-size: 14px;
    color: $main_blue;
    padding: 0;
    margin: 25px auto 0;

    li {
      display: inline-block;

      a {
        text-transform: uppercase;
        @include font-size(1.4);
      }

      &:last-child {
        a {
          transition: none;
          border-bottom: 2px solid $white;

          &:hover {
            border-bottom: none;
          }
        }
      }
    }

    .dropdown {
      max-width: 231px;
      width: 100%;
      margin-right: 40px;
      background-color: $main_blue;

      a {
        display: block;
        font-size: 14px;
        color: $yellow;
        text-transform: none;
        text-align: left;
        font-weight: 500;
        padding: 12px 15px;

        .fa {
          position: absolute;
          display: inline-block;
          height: 100%;
          display: block;
          text-align: center;
          border-left: 1px solid $yellow;
          top: 0;
          bottom: 0;
          right: 0;
          width: 39px;
          padding-top: 12px;

          &:before {
            font-size: 20px;
            color: #b3b3b3;
          }
        }
      }

      .dropdown-menu {
        padding: 0;
        margin-top: 1px;
        background-color: $main_blue;
        width: 100%;
        border-radius: 0;
        overflow: hidden;

        li {
          display: block;

          a {
            transition: $transitionAll;
            border: none;

            &:hover {
              background-color: lighten($main_blue, 10%);
            }
          }
        }
      }
    }
  }

  @include respond-to(xs) {
    padding-top: 10px;

    .personaldrop {
      .dropdown {
        margin-right: 3%;

        .dropdown-menu {
          position: absolute;
        }
      }
    }
  }
}

.program-description {
  text-align: center;
  color: $main_blue;

  h2, h3 {
    text-transform: uppercase;
  }

  h3 {
    margin-top: 41px;
    margin-bottom: 31px;
    @include font-size(3);
  }

  p {
    @include font-size(1.6);
  }

  @include respond-to(xs) {
   h2 {
     @include font-size(2.3);
   }
  }
}

.tabs-wrap {
  display: flex;

  .info-label {
    display: flex;
    flex-basis: 100%;
    max-width: 93px;
    justify-content: center;
    align-items: center;
    max-height: 50px;
    margin-right: 4px;

    background-color: $main_blue;
    text-transform: uppercase;
    text-align: center;
    font-family: $avenir;
    @include font-size(1.6);
    color: $white;
  }

  @include respond-to(xs) {
    flex-direction: column;

    .info-label {
      max-width: 100%;
      margin-bottom: 4px;
      min-height: 28px;
    }

    #program_tabs {
      margin-bottom: 10px;
    }
  }
}

.nav-tabs {
  display: flex;
  width: 100%;
  margin-left: -2px;
  margin-right: -2px;
  justify-content: space-between;

  li {
    display: flex;
    flex-basis: 100%;
    max-width: 33.333%;
    margin-left: 2px;
    margin-right: 2px;
  }

  .active > a {
    border: 4px solid $main_blue;
    background-color: $white;
    color: $yellow;
  }

  .daytab,
  .timetab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;

    background-color: $main_blue;
    text-transform: uppercase;
    text-align: center;
    font-family: $avenir;
    @include font-size(1.6);
    color: $white;

    transition: 0.2s color, background-color;

    &:hover,
    &:focus,
    &:active {
      color: $main_blue;
      border: 4px solid $main_blue;
      background-color: $white;
    }
  }

  .timetab {
    @include respond-to(sm) {
      margin-bottom: 4px;
    }
  }

  .daytab {
    margin-bottom: 4px;

    @include respond-to(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @include respond-to(xs) {
    min-height: 80px;

    .daytab,
    .timetab {
      padding-left: 5px;
      padding-right: 5px;
      min-height: 77px;
      @include font-size(1.5);
    }
  }
}

.tab-content > {
  .tab-pane,
  .dayprogram {
    display: none;
    visibility: hidden;
  }
  .active {
    display: block;
    visibility: visible;
  }
}

/*** Program page - Panel Group ***/

  .panel-group {
    .panel {
      background-color: $main_blue;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        color: $yellow;
        font-family: $avenir;
        @include font-size(1.6);
      }

      > .panel-heading {
        min-height: 125px;

        @include respond-to(xs) {
          min-height: 140px;
        }
      }
    }

    .panel-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 35px 40px 30px;

      .session-name {
        display: block;
        @include font-size(1.6);
        color: $main_blue;
        text-decoration: none;
        text-transform: initial;
        margin-bottom: 0;
        font-weight: bold;
      }

      .room-name {
        text-align: left;
        margin: 0;
        padding: 0;
        @include font-size(1.6);
        color: $main_blue;
        font-weight: normal;
      }

      .toggle-link,
      .session-name-toggle {
        position: relative;
        padding-right: 40px;

        span {
          // margin-left: 58px;
          font-family: $avenir;
          @include font-size(1.6);
          text-transform: initial;

          @include respond-to(xs) {
            display: block;
            margin: 15px 0 0;
          }
        }

        .fa {
          position: relative;
          top: 3px;
          margin-right: 18px;
          color: $main_blue;
          @include font-size(2.6);

          &:after {
            margin-right: 20px;
          }
        }
      }

      .toggle-link {
        &:after {
          content: '\f107';
          position: absolute;
          right: 0;
          top: 0;
          line-height: 15px;
          font-family: 'FontAwesome';
          font-weight: 300;
          color: $main_blue;
          @include font-size(3.5);
          transform: rotate(-180deg);
        }

        &:hover:after {
          color: darken($main_blue, 10%);
        }

        &.collapsed:after {
          transform: rotate(0deg);
        }
      }

      .program-title {
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: bold;

        .session-name {
          color: $main_blue;
        }
      }

      @include respond-to(xs) {
        padding: 15px 40px;
      }
    }

    .panel-body {
      padding: 35px 40px;

      a:not(.toggle-link):not(.person-popup) {
        display: inline;
        margin: 0;
        padding: 0;
        color: $main_blue;
        text-decoration: underline;
        text-transform: uppercase;
        font-family: $avenir;
        @include font-size(1.1);
      }

      p {
        @include font-size(1.6);
        line-height: 22px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .role_title {
        margin-top: 20px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $main_blue;
        font-family: $avenir;
        @include font-size(1.6);
        line-height: 1.3;
      }

      .person-popup {
        display: inline-block;
        @include font-size(1.6);
        text-transform: initial;
        text-decoration: none;
        color: $main_blue;

        p {
          display: inline-block;
          padding: 0;
          margin: 0;
        }
      }
      .speaker-note {
          margin-top: 20px;
          display: block;
      }
    }

    [data-rchild] + .inner-panel-group {
      margin-top: 40px;
    }

    .inner-panel-group {
      margin-left: -47px;
      margin-right: -17px;
      padding-left: 38px;
      padding-right: 36px;

      &:nth-of-type(1) {
        margin-top: 40px;
      }

      > .panel-collapse.in {
        padding-bottom: 35px;
      }

      @include respond-to(xs) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
      }

      .inner-panel-group {
        border: none;

        margin-left: 0;
        margin-right: 0;
        padding-left: 30px;
        padding-right: 0;

        &:nth-of-type(1) {
          margin-top: 25px;
        }

        &:last-of-type {
          .panel-entry:last-child {
            padding-bottom: 0;
          }
        }

        .panel-heading {
          padding: 0 0 20px;
        }

        > .panel-collapse.in {
          padding-bottom: 0;
        }

        .panel-entry {
          &:last-child {
            padding-bottom: 35px;
          }
        }

        .session-name-toggle {
          text-transform: uppercase;
          padding-right: 0;
        }

        .session-name-toggle,
        .room-name {
          color: $main_blue;
        }

        @include respond-to(xs) {
          padding-left: 0;
        }
      }
    }

    .panel-body {
      > .inner-panel-group {
        border: 8px solid $main_blue;

        &:not(:last-child) {
          border-bottom: none;
        }

        > .panel-heading {
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-height: 120px;

          padding-left: 0;
          padding-right: 0;

          a {
            text-transform: initial;
            text-decoration: initial;
            display: flex;
          }

          .room-name{
              display: block;
              margin-left: 41px;

            @include respond-to(xs) {
              margin-left: 0;
            }
          }

          .program-title {
            margin-top: 10px;
          }

          @include respond-to(xs) {
            min-height: 65px;
          }
        }

        .panel-body {
          padding-top: 20px;
        }

        @include respond-to(xs) {
          border-width: 4px;
        }
      }
    }

  /* Program Panel */
  .panel-program {
    .panel-heading + .panel-collapse {
      .list-group,
      .panel-entry,
      .panel-body {
        position: relative;
        border: none;

        &:before {
          content: '';
          position: relative;
          top: 0;
          display: block;
          margin-bottom: 25px;
          width: 100%;
          height: 3px;
          background-color: $main_blue;
        }
      }
    }

    > .panel-heading {
      padding: 15px 25px 25px;

      a, > .session-name {
        color: $main_blue;
      }

      .program-title {
        margin-left: 45px;
        a{
          color: $main_blue;
        }
      }

      .toggle-link {
        &:after {
          top: 10px;
          @include font-size(3.5);
        }

        &:hover:after {
          color: darken($main_blue, 10%);
        }

        &.collapsed:after {
          transform: rotate(0deg);
        }
      }

      @include respond-to(xs) {
        .program-title {
          margin-left: 0;
        }

        padding: 30px;

        .toggle-link:after {
          top: 0;
        }
      }
    }

    .panel-body {
      padding-top: 0;
      padding-left: 70px;
      padding-right: 45px;
      color: $main_blue;

      p {
        font-family: $avenir;
        line-height: 19px;
        color: $main_blue;
      }
    }

    @include respond-to(xs) {
      .panel-body {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
