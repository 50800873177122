/* Hamburger menu */
.hamburger {
  width: 40px;
  height: 26px;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .hamburger-global {
    backface-visibility: hidden;
    position: absolute;
    border: 3px #fff solid;
    border-radius: 0;
    width: 100%;
    transition: $transitionAll;

    &.hamburger-top { top: 0px; }
    &.hamburger-middle { top: 10px; }
    &.hamburger-bottom { top: 20px; }

    &.hamburger-top-click {
      transform: rotate(45deg);
      top: 9px;
    }

    &.hamburger-middle-click { opacity: 0; }

    &.hamburger-bottom-click {
      transform: rotate(-405deg);
      top: 9px;
    }
  }
}