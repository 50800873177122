/*------------------------------------
  # SEARCH PAGE
\*------------------------------------*/
.search{
  background-color: #efefef;
}
.search-top {
  h2 {
    @include font-size(5);
    color: $main_blue;
  }

  p {
    @include font-size(2.5);
  }
}

.search-tabs {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $main_blue;

  li {
    width: 100%;

    a {
      display: block;
      padding-bottom: 25px;
      @include font-size(2.5);
      text-align: center;
      color: $main_blue;

      &:hover {
        border-bottom: 5px solid $yellow;
      }
    }

    &.active > a {
      border-bottom: 5px solid $yellow;
    }
  }

  @include respond-to(xs) {
    margin-top: 30px;

    li {
      a {
        @include font-size(1.5);
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
      }

      &.active > a {
        border-bottom-width: 5px;
      }
    }
  }
}

.search-content {
  margin-top: 0px;
  padding: 60px;
  margin-bottom: 60px;

@include respond-to(xs) {
    margin: 40px 0;
    padding: 20px;

  .panel-group{
  .panel-heading {
    padding: 15px 0px;

    .description{
      margin-bottom: 5px !important;
    }

  }
  .panel-body {
    padding: 0;
  }
}

}
 .panel-group .panel-program>.panel-heading .program-title {
     margin-left: 0;
}
  .tab-pane {
    &.fade {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .panel-group {
    .panel {
      background-color: #ccc;
      color: $black;

      @include respond-to(xs) {
        padding: 20px;
      }

      a {
        color: $black;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .panel-heading {
      .panel-title {
        margin-bottom: 3px;
        text-decoration: underline;

        .toggle{
          &:after {
          color: #aab2bd;
          content: "\f107";
          font-family: FontAwesome;
          font-size: 3.5rem;
          font-size: 35px;
          font-weight: 300;
          line-height: 15px;
          float: right;
          top: 50%;
          position: relative;
          right: 10px;
          transform: rotate(-180deg);
           @include respond-to(xs) {
           right: -20px;
          }
         }
        }
        .collapsed{
          &:after {
            transform: rotate(0deg)

          }
        }

      }

      p {
        line-height: 23px;
        a{
          text-decoration: none;
        }
        &:not(:last-child) {
         margin-top: 10px;
       }
     }

      @include respond-to(xs) {
        .panel-title {
          @include font-size(1.6);
          line-height: 26px;
          padding-right: 20px;
        }

        p {
          line-height: 20px;
          @include font-size(1.4);

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }

    .panel-body {
      padding-top: 25px;

      p {
        line-height: 30px;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }

  .persons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -40px;
  }

  .personc {
    padding: 60px 45px;
    margin-bottom: 40px;

    max-width: 394px;
    width: 100%;

    background-color: $white;
    text-align: center;

    .person-content {
      padding-top: 10px;

      h3 {
        text-transform: initial;
        padding-top: 10px;
      }

      h4 {
        margin-top: 10px;
      }

      h3, h4 {
        color: $main_blue;
      }

      .btn {
        margin-top: 10px;
      }

    }

    @include respond-to(sm) {
      margin-left: auto;
      margin-right: auto;
    }

    @include respond-to(extra_xs) {
      width: 100%;
      padding: 20px;
      text-align: left;
      margin-bottom: 10px;

      h3 {
        font-size: 20px;
      }
    }

  }
}
