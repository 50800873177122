// size
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// space
.body-space {
  padding-bottom: 56px;

  @include respond-to(xs) {
    padding-bottom: 10px;
  }
}

// text
.text-center {
  text-align: center;
}

// grid
.row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.hidden {
  display: none;
}

.show-on-mobile {
  display: none;
}

@include respond-to(xs) {
  .show-on-mobile {
    display: initial;
  }

  .hide-on-mobile {
    display: none;
  }
}