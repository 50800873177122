/** Page Settings **/
*, *:after, *:before {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  min-width: 320px;
  font-family: $base-font-family;
  font-weight: 500;
  color: $base-font-color;

  -webkit-text-size-adjust: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
 -webkit-box-orient: vertical;
 -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flexboxtweener,
.flexboxtweener>body {
  height: 100%;
}

button {
  outline: none;
}

svg {
  transition: $transitionAll;
}

img {
  max-width: 100%;
  height: auto;
}

@viewport {
  user-zoom: fixed;
}

.body-stick {
  padding-top: 82px;
}

.page-hero {
  text-align: center;

  .hero-title {
    color: $main_blue;
    font-family: $avenir;
    @include font-size(3.6);
  }
}

.page-container {
  padding-top: 75px;
  padding-bottom: 75px;

  @include respond-to(xs) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.search-bar {
  position: relative;
  max-width: 245px;
  margin-left: auto;

  input {
    padding: 10px 10px 10px 30px;
    width: 100%;
    outline: none;
    border: none;
    background: none;
    border-bottom: 2px solid $white;
    color: $white;
    font-family: $avenir;
    font-weight: 400;
    @include font-size(1.8);
  }

  button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 20px;

    svg {
      fill: $white;
    }
  }

  @include placeholder {
    opacity: 1;
    color: $white;
  }

  .fa-search {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 17px;
    cursor: pointer;
  }
}


/** Fancybox Popup Spekears **/

.fancybox-overlay {
  // background: rgba($pink, 0.8);
}

.gallery .fancybox-overlay-fixed {
  position: absolute;
}

.fancybox-close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  z-index: 8040;
  content: '1';

  &:hover:after {
    // color: darken($pink, 10%);
  }

  &:after {
    content: '\f00d';
    position: relative;
    font-size: 28px;
    top: -10px;
    font-weight: normal;
    font-family: FontAwesome;
    transition: 0.2s all;
  }
}

.container {
  max-width: 1010px;

  @include respond-to(md) {
    max-width: 100%;
  }
}

.indent {
  padding: 95px 0;

  @include respond-to(xs) {
    padding: 50px 0;
  }
}


.flexb {
  display: flex;
  justify-content: center;

  div {
    width:auto;
    margin:0;
  }

  @include respond-to(xs) {
    display: block;
  }
}

.title {
  text-transform: uppercase;
  text-align: center;

  color: $white;
  @include font-size(2.4);

  &.large-title {
    @include font-size(3.2);
  }

  @include respond-to(xs) {
    font-family: $avenir;
  }
}