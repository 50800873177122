/*------------------------------------
  # FAQ PAGE
\*------------------------------------*/
.faq-container {
  h1 {
    font-family: $avenir;
    font-weight: 600;
    font-size: 40pt;
    padding: 56px 0 40px 0;
    color: $main_blue;
  }

  .page-sub-title {
    font-weight: 500;
    font-size: 15pt;
    padding-bottom: 40px;
    color: $main_blue;
  }

  .faq-inner-block {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(xs) {
      padding-bottom: 50px;
    }

    .panel {
      height:auto;

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      .panel-collapse {
        display: none;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        .panel-inner {
          margin: 0 auto;
          width: 90%;
          font-family: $base-font-family;
          font-weight: 400;
          font-size: 12pt;
          color: $black;
          padding: 20px;

          p {
            text-align: left;
            a{
              color: #4fbfad;
              text-decoration: underline;
            }
          }
        }
      }

      .accordion-title {
        font-weight: 300;
        color: $main_blue;
        font-size: 15pt;
        padding: 20px 50px 20px 20px;
        background-color: #cccccc;
        display:block;
        position: relative;
        width: 100%;
        text-align: left;
        border: none;
        font-family: $avenir;

        &:after {
          position: absolute;
          display: block;
          content: '';
          top: 50%;
          right: 15px;
          width: 30px;
          height: 15px;
          margin-top: -5px;
          background: transparent url('/assets/img/carrot.png') no-repeat;
        }

        &.active {
          &:after {
            transform: rotate(180deg);
            right: 25px;
          }

          + .panel-collapse {
            height: auto;
            display: block;
            opacity: 1;
            animation: anim .3s ease-in-out;

            @keyframes anim {
              0% {
                display: none;
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }

            @include respond-to(xs) {
              max-height: 600px;
            }
          }
        }

        @include respond-to(sm) {
          font-size: 14pt
        }

        @include respond-to(xs) {
          font-size: 12pt;
          padding-right: 60px;
        }
      }

    }
  }
}