
/*------------------------------------
  SPONSORS PAGE
\*------------------------------------*/
.sponsors-section {
  position: relative;
  padding-bottom: 60px;
  text-align: center;
  color: $black;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    max-width: 660px;
    width: 100%;
    height: 1px;

    background-color: $black;
  }

  .title {
    @include font-size(3.5);
    margin-bottom: 20px;
    color: $black;
  }

  img {
    max-width: 280px;
    margin-top: 20px;
  }

  @include respond-to(xs) {
    padding: 40px 0;

    &:not(:last-child):after {
      max-width: 300px;
    }
  }
}