/* Typography
================================================== */

// Base Font Lion
$base-font-family: 'Avenir Next', 'Arial', sans-serif;
$base-font-size: 15px;
$base-font-color: $main_blue;
$base-line-height: 1.2;

// Monserat
$avenir: 'Avenir Next', sans-serif;
$avenir_demi: 'Avenir Next Demi', sans-serif;


h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: $main_blue;
  text-decoration: none;
  @include font-size(1.6);
  transition: $transitionAll;


  &:hover,
  &:focus,
  &:active {
    color: $yellow;
    text-decoration: none;
  }
}

h2 {
  @include font-size(3.6);
  font-family: $avenir;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 7.2px;

  @include respond-to(sm) {
    @include font-size(3);
  }

  @include respond-to(xs) {
    @include font-size(2);
  }

}

h3 {
  @include font-size(3.4);
}

h4 {
  @include font-size(1.8);
}

p {
  @include font-size(2.3);
}