/* Navigation
================================================== */

.navbar {
  position: relative;
  background: $main_blue;
  padding-bottom: 20px;

  .row {
    display: flex;
    align-items: center;
  }

  // Hover effect for desktop nav
  &.desktop-nav .nav-list li:hover {
    ul {
      display: block;
    }
  }

  .nav-list {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    margin-left: 35px;

    li {
      &:not(:last-child) {
        margin-right: 35px;

        @include respond-to(sm) {
          margin-right: 15px;
        }
      }
       &.mob-menu-item {
        display: none
      }

      &.is-active > a:before {
        width: 100%;
      }

      a {
        color: $white;
        font-family: $avenir;
        @include font-size(1.8);
        font-weight: 500;

        display: block;
        position: relative;
        transition: $transitionAll;
        text-decoration: none;
        white-space: nowrap;

        &:hover,
        &:active,
        &.current-menu-item {
          color: $yellow;
          background: none !important;
        }
      }

      ul {
        display: none;
        position: absolute;

        padding-left: 25px;
        padding-right: 25px;
        background-color: $white;

        li {
          display: block;
          width: 100%;

          a {
            padding: 15px 0;
            z-index:100;
          }
        }
      }
    }

    @include respond-to(sm) {
      margin-left: 10px;
    }
  }

  .search-bar {

    form {
      display: flex;

    }
  }
}

.navbar-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 300;
  padding-top: 10px;
  padding-bottom: 10px;
}


@include respond-to(xs) {
  .navbar {
    position: fixed;
    height: 100%;
    width: 100%;
    padding-top: 56px;
    padding-bottom: 20px;
    transform: translateX(-100%);
    transition: transform .5s ease;
    overflow-y: scroll;
    z-index: 100;
    box-shadow: none;

    ul.nav-list {
      flex-direction: column;
      margin-left: 0;

      li {
        a {
          padding: 15px 10px;
          width: max-content;
        }

        li a {
          background-color: transparent;
          padding: 10px 25px;
        }
        &.mob-menu-item {
          display: block;
        }
        &.hover ul {
          position: static;
          display: block;
        }
      }
    }

    .search-bar {
      margin-top: 0;
      max-width: 100%;
    }
  }

  .js-nav {
    html, body {
      overflow: hidden;
    }
    .navbar {
      transform: translateX(0);
      overflow-y: scroll;
    }
  }
}

@include respond-to(xs) {
.splash-nav{
 padding: 6px 0;
 max-height: 55px;
 box-shadow: 0 2px 6px 0 #c93b58;

.show-on-mobile{
  margin-left: -5px !important;
  font-size: 15px !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
}
.nav-splash{
  margin-right: -10px;
  ul{
  li{
    a{
font-size: 10px;
    font-weight: 400;
    }
  }
}

}
}
}