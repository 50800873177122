/* Buttons
================================================== */
.btn {
  display: table;
  line-height: 42px;
  margin-left: auto;
  margin-right: auto;

  height: 42px;
  max-width: 232px;
  width: 100%;

  font-family: $avenir;
  text-transform: uppercase;
  @include font-size(1.6);
  text-align: center;
  color: $main_blue;
  border: 1px solid $main_blue;

  transition: $transitionAll;

  @at-root button#{&} {
    line-height: initial;
    border: none;
  }
}

.btn-white {
  background-color: $white;
  color: $main_blue;

  &:hover,
  &:focus,
  &:active {
    background-color: $main_blue;
    color: $white;
  }
}


.btn-back {
  margin-top: 20px;

  &:hover,
  &:focus,
  &:active {
    color: darken($white, 5%);

    &:after {
      color: darken($white, 5%);
    }
  }
}